.wrapper {
    background-color: #272525;
    height: 250px;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.rangeWrapper {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.range {
    width: 100%;

    .desc {
        font-size: 12px;
        padding: 0 15px 0 25px;
        display: flex;
        justify-content: space-between;

        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            svg {
                width: 10px;
                height: 10px;

                path {
                    fill: #494545;
                }
            }
        }
    }

    .slider {
        position: relative;
        width: 100%;
        height: 50px;
        padding: 0 10px;
        border: 10px solid #494545;
        border-radius: 50px;
        display: flex;
        align-items: center;
    }

    .inputWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;

        .label {
            display: block;
            width: 25px;
            height: 24px;
            background: royalblue;
            pointer-events: none;
            position: absolute;
        }

        .input {
            width: 100%;
            height: 10px;
            background: #FF9C00;
            appearance: none;
            border-radius: 50px;
            overflow: hidden;
            outline: none;
            transition: all .2s ease;

            &::-webkit-slider-thumb {
                height: 24px;
                width: 24px;
                border-radius: 3px;
                background: red;
                appearance: none;
                cursor: pointer;
                border: 0;
                box-shadow: -2000px 0 0 1990px #01D501;
                position: relative;
                transition: all .2s ease;
            }

            &::-moz-range-thumb {

            }

            &_over {
                background: #01D501;

                &::-webkit-slider-thumb {
                    box-shadow: -2000px 0 0 1990px #FF9C00;
                }

                &::-moz-range-thumb {

                }
            }
        }
    }

    .cubeWrapper {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 25px;

        .cube {
            position: relative;
            width: 50px;
            height: 50px;
            background: url("../../../assets/cube.png") no-repeat center center / contain;
            color: #272525;
            pointer-events: none;
            transition: all .2s ease;

            &.win {
                color: #01D501;
            }

            &.lose {
                color: #FF9C00;
            }

            span {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: -27px;
            }
        }
    }
}