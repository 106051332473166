.control {
    padding: 10px 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.control > button {
    margin-bottom: 10px;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.number {
    position: relative;

    .btn {
        position: absolute;
        right: 10px;
        bottom: 15px;

        svg path {
            transition: all .2s ease;

            &:first-child {
                stroke: #fff;
            }

            &:last-child {
                fill: #fff;
            }
        }

        &:active {
            svg path {
                &:first-child {
                    stroke: #01D501;
                }

                &:last-child {
                    fill: #01D501;
                }
            }
        }
    }
}

.autobet {
    position: relative;
}

.autobet .checkbox {
    position: absolute;
    top: 0;
    right: 0;
    flex-direction: row-reverse;
}

