.overflow {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    transition: all .3s ease;
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overflow.active {
    opacity: 1;
    pointer-events: auto;
}

.popup {
    width: calc(100vw - 40px);
    max-height: 90vh;
    overflow-y: auto;
    background-color: #272525;
    border-radius: 10px;
    position: relative;
}

.popup .close {
    position: absolute;
    top: 25px;
    right: 25px;
}